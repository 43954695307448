import {
  Swiper, Pagination, Navigation, Mousewheel,
} from 'swiper/js/swiper.esm';

import PhotoSwipe from 'photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';

Swiper.use([Navigation, Pagination, Mousewheel]);

/* 1 of 2 : SWIPER ################################### */
// const mySwiper = new Swiper('.swiper-container', {
new Swiper('.swiper-container', {
  // If swiper loop is true set photoswipe counterEl: false (line 175 her)
  loop: false,
  /* slidesPerView || auto - if you want to set width by css like flickity.js
   * layout - in this case width:80% by CSS */
  // slidesPerView: 'auto',
  slidesPerView: 'auto',
  spaceBetween: 10,
  // grabCursor: true,
  centerInsufficientSlides: true,
  // centeredSlides: true,
  // If we need pagination
  // pagination: {
  //   el: '.swiper-pagination',
  //   clickable: true,
  //   type: 'bullets',
  //   // renderBullet(index, className) {
  //   //   return `<span class="${className}">${index + 1}</span>`;
  //   // },
  // },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

function parseThumbnailElements(el) {
  const thumbElements = el.childNodes;
  const numNodes = thumbElements.length;
  const items = [];
  let figureEl;
  let linkEl;
  let size;
  let item;

  for (let i = 0; i < numNodes; i += 1) {
    figureEl = thumbElements[i]; // <figure> element

    // include only element nodes
    if (figureEl.nodeType !== 1) {
      continue;
    }

    linkEl = figureEl.children[0]; // <a> element

    const dataSize = linkEl.getAttribute('data-size');

    // create slide object
    item = {
      src: linkEl.getAttribute('href'),
    };
    if (dataSize) {
      size = dataSize.split('x');
      item.w = parseInt(size[0], 10);
      item.h = parseInt(size[1], 10);
    }

    if (figureEl.children.length > 1) {
      // <figcaption> content
      // item.title = figureEl.children[1].innerHTML;
      item.title = linkEl.getAttribute('data-desc');
    }

    if (linkEl.children.length > 0) {
      // <img> thumbnail element, retrieving thumbnail url
      item.msrc = linkEl.children[0].getAttribute('src');
    }

    item.el = figureEl; // save link to element for getThumbBoundsFn
    items.push(item);
  }

  return items;
}

// 2 of 2 : PHOTOSWIPE #######################################
function openPhotoSwipe(index, galleryElement, disableAnimation, fromURL) {
  const pswpElement = document.querySelectorAll('.pswp')[0];
  // const gallery;
  // let options;
  // let items;

  const items = parseThumbnailElements(galleryElement);

  // define options (if needed)

  const options = {
    /* "showHideOpacity" uncomment this If dimensions of your small thumbnail
     * don't match dimensions of large image */
    showHideOpacity: true,

    // Buttons/elements
    loop: false,
    closeEl: true,
    captionEl: true,
    fullscreenEl: true,
    zoomEl: false,
    shareEl: true,
    counterEl: false,
    arrowEl: true,
    preloaderEl: true,
    // define gallery index (for URL)
    galleryUID: galleryElement.getAttribute('data-pswp-uid'),
    getThumbBoundsFn(ind) {
      // See Options -> getThumbBoundsFn section of documentation for more info
      const thumbnail = items[ind].el.getElementsByTagName('img')[0]; // find thumbnail
      const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
      const rect = thumbnail.getBoundingClientRect();

      return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
    },
  };

  // PhotoSwipe opened from URL
  if (fromURL) {
    if (options.galleryPIDs) {
      // parse real index when custom PIDs are used
      // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
      for (let j = 0; j < items.length; j += 1) {
        if (items[j].pid === index) {
          options.index = j;
          break;
        }
      }
    } else {
      // in URL indexes start from 1
      options.index = parseInt(index, 10) - 1;
    }
  } else {
    options.index = parseInt(index, 10);
  }

  // exit if index not found
  if (Number.isNaN(options.index)) {
    return;
  }

  if (disableAnimation) {
    options.showAnimationDuration = 0;
  }

  // Pass data to PhotoSwipe and initialize it
  const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, options);
  gallery.init();

  /* EXTRA CODE (NOT FROM THE CORE) - UPDATE SWIPER POSITION TO THE CURRENT
   * ZOOM_IN IMAGE (BETTER UI) */

  // photoswipe event: Gallery unbinds events
  // (triggers before closing animation)
  gallery.listen('unbindEvents', () => {
    // This is index of current photoswipe slide
    // const getCurrentIndex = gallery.getCurrentIndex();
    // Update position of the slider
    // mySwiper.slideTo(getCurrentIndex, false);
  });
  // gallery.listen('imageLoadComplete', function(index, item) {
  //   const linkEl = item.el.children[0];
  //   // var img = item.container.children[0];
  //   const img = linkEl.getAttribute('href');
  //   console.log('imageLoadComplete for', item);
  //   console.log('linkEl', linkEl);
  //   console.log('img', img);
  //   if (!linkEl.getAttribute('data-size')) {
  //     linkEl.setAttribute('data-size', img.naturalWidth + 'x' + img.naturalHeight);
  //     item.w = img.naturalWidth;
  //     item.h = img.naturalHeight;
  //     gallery.invalidateCurrItems();
  //     gallery.updateSize(true);
  //   }
  // });
}


function initPhotoSwipeFromDOM(gallerySelector) {
  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)

  // find nearest parent element
  const closest = function closest(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
  };

  // triggers when user clicks on thumbnail
  function onThumbnailsClick(e) {
    // e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    } else {
      e.returnValue = false;
    }

    const eTarget = e.target || e.srcElement;

    // find root element of slide
    const clickedListItem = closest(eTarget, el => el.tagName && el.tagName.toUpperCase() === 'LI');

    if (!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    const clickedGallery = clickedListItem.parentNode;
    const { childNodes } = clickedListItem.parentNode;
    const numChildNodes = childNodes.length;
    let nodeIndex = 0;
    let index;

    for (let i = 0; i < numChildNodes; i += 1) {
      if (childNodes[i].nodeType !== 1) {
        continue;
      }

      if (childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }
      nodeIndex += 1;
    }

    if (index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe(index, clickedGallery);
    }
    // return false;
    // return;
  }

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  function photoswipeParseHash() {
    const hash = window.location.hash.substring(1);
    const params = {};

    if (hash.length < 5) {
      return params;
    }

    const vars = hash.split('&');
    for (let i = 0; i < vars.length; i += 1) {
      // if (!vars[i]) {
      //   continue;
      // }
      // const pair = vars[i].split('=');
      // if (pair.length < 2) {
      //   continue;
      // }
      const pair = vars[i].split('=');
      if (vars[i] && pair.length >= 2) {
        // continue;
        params[pair[0]] = pair[1];
      }
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  }


  // loop through all gallery elements and bind events
  const galleryElements = document.querySelectorAll(gallerySelector);

  for (let i = 0, l = galleryElements.length; i < l; i += 1) {
    galleryElements[i].setAttribute('data-pswp-uid', i + 1);
    galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  const hashData = photoswipeParseHash();
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
}

// execute above function
initPhotoSwipeFromDOM('.my-gallery');
